import { Asset, RichTextNodeType } from "contentful";

import { AddonsAndBenefitsSection as AddonsAndBenefitsType } from "#components/contentful/contentful-v2/AddonsAndBenefits/AddonsAndBenefits.types";
import { SplitLinkedCardSection as SplitLinkedCardType } from "#components/contentful/contentful-v2/SplitLinkedCard/SplitLinkedCard.types";
import { SubscriptionBannerSection as SubscriptionBannerType } from "#components/contentful/EmailSubscriptionBanner/EmailSubscriptionBanner.types";
import { RichTextSection as RichTextType } from "#components/contentful/RichTextComponent/RichTextComponent.types";
import {
  buttonLinkFields,
  copyFields,
  CopyTypes,
  imageFields,
  ImageTypes,
  metadataFields,
  MetadataTypes,
  NavigatorTypes,
  PageContentTemplates,
  richTextFields,
  RichTextTypes,
  themeFields,
  ThemeTypes,
} from "#components/contentful/v5/PageContent/types";
import { ABTestOption } from "#v2-components/organisms/ABTest/types";

import { AddOnPage as AddOnPageType } from "./AddOnPage/AddOnPage.types";
import { BannerSection as BannerSectionType } from "./Banner/Banner.types";
import { BlenderSection as BlenderSectionType } from "./Blender/Blender.types";
import { BlogCardSection as BlogCardSectionType } from "./BlogCardSection/BlogCardSection.types";
import { BlogSection as BlogSectionType } from "./BlogSection/BlogSection.types";
import { CalculatorSection as CalculatorSectionType } from "./CarFeature/CarFeature.types";
import { CommitmentSection as CommitmentSectionType } from "./Commitment/Commitment.types";
import { CommonQuestionsSection as CommonQuestionsType } from "./CommonQuestions/CommonQuestions.types";
import { AccordionSection as AccordionSectionType } from "./contentful-v2/AccordionSection/AccordionSection.types";
import { HeroWithIcon as HeroWithIconType } from "./contentful-v2/HeroWithIcon/HeroWithIcon.types";
import { HomeHeroSection as HomeHeroType } from "./contentful-v2/HomeHero/HomeHero.type";
import { HowItWorks as HowItWorksType } from "./contentful-v2/HowItWorks/HowItWorks.types";
import { LargeSection as LargeSectionType } from "./contentful-v2/LargeSection/LargeSection.types";
import { Navbar as NavbarType } from "./contentful-v2/Navbar/Navbar.types";
import { SmallSection as SmallSectionType } from "./contentful-v2/SmallSection/SmallSection.types";
import { ValuePropSection as ValuePropSectionType } from "./contentful-v2/ValuePropSection/ValuePropSection.types";
import { CustomerTestimonialSection as CustomerTestimonialSectionType } from "./CustomerTestimonials/CustomerTestimonials.types";
import { DescriptionPanel } from "./DescriptionPanel/DescriptionPanel.types";
import { DetailsSection as DetailsSectionType } from "./Details/Details.types";
import { DividerSection as DividerSectionType } from "./DividerSection/DividerSection.types";
import { FAQSection as FAQSectionType } from "./FAQSection/FAQSection.types";
import { FeatureComparisonSection as FeatureComparisonSectionType } from "./FeatureComparison/FeatureComparison.types";
import { FooterSection as FooterSectionType } from "./Footer/Footer.types";
import { GetInTouchCareersCard as GetInTouchCareersCardType } from "./GetInTouchCareersCard/GetInTouchCareersCard.types";
import { HeaderSection as HeaderSectionType } from "./Header/Header.types";
import { IconBanner as IconBannerType } from "./IconBanner/IconBanner.types";
import { NewsSection } from "./InTheNews/InTheNews.types";
import { JobOpeningsSection as JobOpeningsType } from "./JobOpenings/JobOpenings.types";
import { LargeImageSection as LargeImageType } from "./LargeImage/LargeImage.types";
import { LinkedTileCard as LinkedTileCardType } from "./LinkedTileCard/LinkedTileCard.types";
import { MakePage as MakePageType } from "./MakePage/MakePage.types";
import { MetadataSection } from "./Metadata/types";
import { PartnerHeroSection } from "./PartnerHero/PartnerHero.types";
import { ContactUsSection as ContactUsSectionType } from "./ReadyToApplyCard/ReadyToApplyCard.types";
import {
  ResponsiveImageProps,
  ResponsiveImageSection,
} from "./ResponsiveImage/ResponsiveImage.types";
import { RoleDescriptionSection as RoleDescriptionSectionType } from "./RoleDescription/RoleDescrition.types";
import { StackableSection } from "./StackablePanes/StackablePanes.types";
import { StackableStepsSection as StackableStepsType } from "./StackableSteps/StackableSteps.types";
import { TeamCarousel } from "./TeamCarousel/TeamCarousel.types";
import { TeslaHeroSection as TeslaHeroType } from "./TeslaHero/TeslaHero.types";
import { TestimonialsSection as TestimonialsSectionType } from "./Testimonials/Testimonials.types";
import { TitleSection as TitleSectionType } from "./TitleSection/TitleSection.types";

enum CustomNodeType {
  EmbeddedAsset = "embedded-asset-block",
  Icon = "icon",
}

enum HeadingType {
  H1 = "heading-1",
  H2 = "heading-2",
  H3 = "heading-3",
  H4 = "heading-4",
  H5 = "heading-5",
  H6 = "heading-6",
}

type CustomContent = IconContent | EmbeddedAssetContent;

type NativeContent = HeadingContent | HyperlinkContent;

type AllContent = PotentiallyNestedContent | CustomContent | NativeContent;

// Good example usage of Template Literal Types
// Documentation: https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html
type DeprecatedNativeNodeTypes = Exclude<
  RichTextNodeType,
  `${HeadingType}` | "hyperlink"
>;

interface TextContent {
  marks?: {
    type: string;
  }[];
  value?: string;
}

// PotentiallyNestedContent refers to an assortment of contentful native rich text
// This should be deprecated and we should move to specific interfaces for each nodeType
interface PotentiallyNestedContent {
  data: {
    uri?: string;
    target?: Asset;
  };
  nodeType: DeprecatedNativeNodeTypes;
  marks?: {
    type: string;
  }[];
  content: AllContent[];
  value?: string;
}

/**
 * NATIVE CONTENT
 */
interface HyperlinkContent {
  nodeType: "hyperlink";
  data: {
    uri?: string;
    target?: Asset;
  };
  content: TextContent[];
}

interface HeadingContent extends TextContent {
  nodeType: HeadingType;
  content: {
    value: string;
  }[];
}

export type ContentPostData = {
  url?: string;
  blogTitle: string;
  heroImage: Asset;
  blogBody: PotentiallyNestedContent;
  metadescriptionlong: string;
  titleTag: string;
};

/**
 * CUSTOM CONTENT
 */

interface IconContent {
  nodeType: CustomNodeType.Icon;
  value: string;
}

interface EmbeddedAssetContent {
  nodeType: CustomNodeType.EmbeddedAsset;
  data: {
    uri?: string;
    target?: Asset;
  };
}

/**
 * CUSTOM CONTENT MODELS
 */

export enum CustomContentModelType {
  // Generic (v5) Page Sections
  PageContent = "pageContent",
  ButtonLink = "navigator",
  ResponsiveImage = "responsiveImage",
  Theme = "theme",
  Copy = "copy",
  RichText = "richText",
  Metadata = "metadata",
  ABTest = "abTestOption",

  // Non-Generic (v4) Page Sections
  HeaderSection = "headerSection",
  FooterSection = "footerSection",
  DetailsSection = "detailsSection",
  CalculatorSection = "calculatorSection",
  StackablesSection = "stackablesSection",
  FeatureComparisonSection = "featureComparisonSection",
  ContactUsSection = "contactUsSection",
  PartnersAndQuotesSection = "partnersAndQuotesSection",
  CommitmentSection = "commitmentSection",
  FAQSection = "faqSection",
  InTheNewsSection = "inTheNewsSection",
  TestimonialsSection = "testimonialsSection",
  TitleSection = "titleSection",
  JobOpeningsSection = "jobOpeningsSection",
  RoleDescriptionSection = "roleDescriptionSection",
  TeslaHero = "teslaHeroSection",
  TeamCardsSection = "teamCardsSection",
  StackableStepsSection = "stackableStepsSection",
  CommonQuestionsSection = "commonQuestionsSection",
  BlenderSection = "blender",
  LargeImageSection = "largeImageSection",
  HomeHeroSection = "homeHero",
  LinkedTileCard = "linkedTileCard",
  SplitLinkedCard = "splitLinkedCard",
  HowItWorks = "howItWorks",
  Navbar = "navbar",
  AddonsAndBenefits = "addonsAndBenefits",
  RichTextComponent = "richTextComponent",

  // Add on page
  AddOnPage = "addOnPage",

  // Make page
  MakePage = "makePage",

  // Initially built for Partner
  SectionPartnerHero = "partnerHeroSection",
  BannerSection = "bannerSection",
  IconBannerSection = "iconBannerSection",

  // unused in top level generic page
  NewsComponent = "newsComponent",
  Stackable = "stackablePaneComponent",
  DescriptionPanelComp = "descriptionPanel",

  // Blog
  BlogSection = "blogSection",
  BlogCardSection = "blogCardsSection",

  // Careers Sections
  GetInTouchCareersCard = "getInTouchCareersCard",

  Divider = "divider",
  EmailSubscriptionBanner = "emailSubscriptionBanner",

  // Smart charging components
  AccordionSection = "accordionSection",
  ValuePropSection = "valuePropSection",
  HeroWithIcon = "heroWithIcon",
  SmallSection = "smallSection",
  LargeSection = "largeSection",

  // Error (never returned from contentful)
  Error = "error",
  // Mock (never returned from contentful)
  Mock = "mock",
}

export interface ContentfulCustomNode<T, ID extends CustomContentModelType> {
  contentTypeID?: ID; // This is not available, but added later for type narrowing
  fields: T;
  metadata: { tags: [] };
  sys: {
    id: string;
    type: "Entry";
    createdAt: string;
    updatedAt: string;
    contentType: {
      sys: {
        type: "Link";
        linkType: "ContentType";
        id: ID;
      };
    };
  };
}

export interface ContentfulAssetNode {
  fields: {
    description: string;
    title: string;
    file: {
      contentType: string;
      fileName: string;
      url: string;
      details: {
        size: number;
        image: {
          width: number;
          height: number;
        };
      };
    };
  };
  metadata: { tags: [] };
  sys: {
    id: string;
    type: "Asset";
    createdAt: string;
    updatedAt: string;
  };
}

/* This will be returned if content is deleted/unpublished but still referenced */
export type ErrorContentType = ContentfulCustomNode<
  unknown,
  CustomContentModelType.Error
>;

/* MPR, 2024/4/16: These are the generic sections for "PageContent", which is similarly generic to the "Generic Page" */
/* the reused image and metadata fields are serving double duty, and are defined elsewhere */
export type ButtonLinkUnstructuredContent = ContentfulCustomNode<
  buttonLinkFields,
  CustomContentModelType.ButtonLink
>;
export type ImageUnstructuredContent = ContentfulCustomNode<
  imageFields,
  CustomContentModelType.ResponsiveImage
>;
export type ThemeUnstructuredContent = ContentfulCustomNode<
  themeFields,
  CustomContentModelType.Theme
>;
export type CopyUnstructuredContent = ContentfulCustomNode<
  copyFields,
  CustomContentModelType.Copy
>;
export type RichTextUnstructuredContent = ContentfulCustomNode<
  richTextFields,
  CustomContentModelType.RichText
>;
export type MetadataUnstructuredContent = ContentfulCustomNode<
  metadataFields,
  CustomContentModelType.Metadata
>;
/* MPR, 2024/4/19: This is where the magic happens. This allows us to make our association between our
 * enums and our field types */
export type UnstructuredFieldType<T> = T extends CopyTypes.Author
  ? CopyUnstructuredContent
  : T extends CopyTypes.Body
  ? CopyUnstructuredContent
  : T extends CopyTypes.Disclaimer
  ? CopyUnstructuredContent
  : T extends CopyTypes.Heading
  ? CopyUnstructuredContent
  : T extends CopyTypes.Key
  ? CopyUnstructuredContent
  : T extends CopyTypes.ListItem
  ? CopyUnstructuredContent
  : T extends CopyTypes.Location
  ? CopyUnstructuredContent
  : T extends CopyTypes.Subheading
  ? CopyUnstructuredContent
  : T extends CopyTypes.Subheading
  ? CopyUnstructuredContent
  : T extends NavigatorTypes.Button
  ? ButtonLinkUnstructuredContent
  : T extends NavigatorTypes.Link
  ? ButtonLinkUnstructuredContent
  : T extends ImageTypes.Icon
  ? ImageUnstructuredContent
  : T extends ImageTypes.Image
  ? ImageUnstructuredContent
  : T extends ThemeTypes.Theme
  ? ThemeUnstructuredContent
  : T extends MetadataTypes.Metadata
  ? MetadataUnstructuredContent
  : T extends RichTextTypes.RichText
  ? RichTextUnstructuredContent
  : never;
/* MPR, 2024/4/19: this is the type we will narrow to get to our good final type */
export type UnstructuredContent =
  | ButtonLinkUnstructuredContent
  | ImageUnstructuredContent
  | ThemeUnstructuredContent
  | CopyUnstructuredContent
  | RichTextUnstructuredContent
  | MetadataUnstructuredContent;

export type PageContentFields = {
  referenceName: string;
  template: PageContentTemplates;
  templateVersion: number;
  content: UnstructuredContent[];
};
export type PageContentSection = ContentfulCustomNode<
  PageContentFields,
  CustomContentModelType.PageContent
>;

export type ContentfulCustomNodeImpl =
  | PageContentSection
  | ABTestOption
  | ButtonLinkUnstructuredContent
  | ThemeUnstructuredContent
  | CopyUnstructuredContent
  | RichTextUnstructuredContent
  | MetadataUnstructuredContent
  | ImageUnstructuredContent
  | BannerSectionType
  | CalculatorSectionType
  | CommitmentSectionType
  | ContactUsSectionType
  | CustomerTestimonialSectionType
  | DescriptionPanel
  | DetailsSectionType
  | FeatureComparisonSectionType
  | FooterSectionType
  | FAQSectionType
  | PartnerHeroSection
  | NewsSection
  | StackableSection
  | TestimonialsSectionType
  | ResponsiveImageSection
  | HeaderSectionType
  | JobOpeningsType
  | TeamCarousel
  | GetInTouchCareersCardType
  | RoleDescriptionSectionType
  | TitleSectionType
  | JobOpeningsType
  | TeslaHeroType
  | StackableStepsType
  | CommonQuestionsType
  | BlenderSectionType
  | LargeImageType
  | BlogSectionType
  | BlogCardSectionType
  | IconBannerType
  | DividerSectionType
  | HomeHeroType
  | LinkedTileCardType
  | SplitLinkedCardType
  | HowItWorksType
  | NavbarType
  | AddonsAndBenefitsType
  | AddOnPageType
  | MakePageType
  | RichTextType
  | SubscriptionBannerType
  | AccordionSectionType
  | ValuePropSectionType
  | HeroWithIconType
  | SmallSectionType
  | LargeSectionType
  | MetadataSection
  | ErrorContentType;

/** Add component prop types to this type as needed */
export type ContentfulComponentProps = ResponsiveImageProps;

export interface ContentfulPage {
  metaTag?: string;
  titleTag?: string;
  pageSections: ContentfulCustomNodeImpl[];
}
