import CheckCircle from "@heroicons/react/24/outline/CheckCircleIcon";
import { useState } from "react";

import LOSClient from "#api.los/client";
import { TenetCircle } from "#components/base/Icon/Icon";
import { useRouterContext } from "#components/provider/Router";
import { useCookie } from "#hooks/useCookies/useCookies";
import Button from "#v2-components/molecules/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "#v2-components/molecules/Button/Button.types";

import SectionWrapper from "../../../v2-components/templates/section-wrapper/SectionWrapper";
import s from "./EmailSubscriptionBanner.module.scss";
import { SubscriptionBannerFields } from "./EmailSubscriptionBanner.types";
import { validateEmail } from "./EmailSubscriptionBanner.utils";

export const ModuleName = "Email Subscription Banner";

const EmailSubscriptionBanner = ({
  fields,
}: {
  fields: SubscriptionBannerFields;
}) => {
  const { title, subtitle } = fields;
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState(false);
  const { value: isSubscribedValue, updateCookie: updateIsSubscribedCookie } =
    useCookie("is-subscribed-email-list", "false");
  const isSubscribed = isSubscribedValue === "true";
  const { asPath } = useRouterContext();
  const urlWithoutQuery = asPath.split("?")[0];

  const onSubmit = async () => {
    const isValid = validateEmail(email);
    if (isValid) {
      updateIsSubscribedCookie("true");
      await LOSClient.tracking.subscribeEmail(email, urlWithoutQuery);
      return;
    }
    setFormError(true);
  };

  return (
    <SectionWrapper>
      <div className={s.container}>
        <div className={s.body}>
          <div className={s.headerSection}>
            <div className={s.svgWrapper}>
              <TenetCircle />
            </div>
            <div>
              <h2>{title}</h2>
              <h3 className={s.grayText}>{subtitle}</h3>
            </div>
          </div>
          <div>
            <div className={s.inputContainer}>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setFormError(false);
                }}
                disabled={isSubscribed}
                placeholder="Enter your email"
              />
              {/* note: circle check svg for success */}
              {!isSubscribed ? (
                <Button
                  className={s.subscribeButton}
                  onClick={onSubmit}
                  variant={ButtonVariant.DARK_FILLED}
                  forceSize={ButtonSize.SMALL}
                  data-cy="subscribe"
                >
                  Subscribe
                </Button>
              ) : (
                <p className={s.purpleText}>
                  You&apos;re in! <CheckCircle />
                </p>
              )}
            </div>
            {formError && (
              <p className={s.errorMessage}>Enter a valid email address</p>
            )}
          </div>
        </div>
        <div>
          <p className={s.grayText}>
            By providing your email address, you agree to receive marketing
            communications from Tenet. For more information and how to
            unsubscribe at any time, see Tenet&apos;s{" "}
            <a
              href="https://tenet.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default EmailSubscriptionBanner;
